import React, { Component } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { obfuscatedCoordinates } from '../../util/maps';
import { Map, OnlineClassIcon } from '../../components';
import config from '../../config';

import css from './ListingPage.module.css';

class SectionMapMaybe extends Component {
  constructor(props) {
    super(props);
    this.state = { isStatic: true };
  }

  render() {
    const { className, rootClassName, geolocation, publicData, listingId } = this.props;

    const isConnected = publicData && publicData.connected;
    const platform = publicData && publicData.platform;
    const classes = classNames(rootClassName || css.sectionMap, className);

    const address = publicData && publicData.location ? publicData.location.address : '';
    const cacheKey = listingId ? `${listingId.uuid}_${geolocation.lat}_${geolocation.lng}` : null;

    const mapProps = config.maps.fuzzy.enabled
      ? { obfuscatedCenter: obfuscatedCoordinates(geolocation, cacheKey) }
      : { address, center: geolocation };
    const map = <Map {...mapProps} useStaticMap={this.state.isStatic} />;
    console.log(isConnected, platform);
    return (
      <div className={classes}>
        <h2 className={css.locationTitle}>
          <FormattedMessage id="ListingPage.locationTitle" />
        </h2>
        {this.state.isStatic ? (
          <div className={css.mapContainer}>
            <div className={css.onlineIcon}>{isConnected ? <OnlineClassIcon /> : null}</div>
            <button
              className={css.map}
              onClick={() => {
                this.setState({ isStatic: false });
              }}
            >
              {map}
            </button>
          </div>
        ) : (
          <div className={css.map}>
            {isConnected ? <OnlineClassIcon /> : null}

            {map}
          </div>
        )}
      </div>
    );
  }
}

SectionMapMaybe.defaultProps = {
  rootClassName: null,
  className: null,
  geolocation: null,
  listingId: null,
};

SectionMapMaybe.propTypes = {
  rootClassName: string,
  className: string,
  geolocation: propTypes.latlng,
  listingId: propTypes.uuid,
};

export default SectionMapMaybe;
